<template>
  <section>
    <section class="navBack">
      <v-container class="container-custom">
        <v-row>
          <v-col md="12" class="topNav">
            <ul class="navList">
              <li>Home</li>
              <li><v-icon> mdi-chevron-right </v-icon> Blogs</li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <v-container class="container-custom py-15">
      <v-row>
        <v-col md="8" cols="12" v-if="loading">
          <Loading/>
        </v-col>
        <v-col md="8" v-if="!loading" cols="12">
          <BlogBox
            :hr="true"
            v-for="(data, index) in blogs"
            :key="index + 'blogHr'"
            :item="data"
          />
          <v-row class="justify-center mt-10" v-if="next != undefined">
            <v-btn class="primary"> View More </v-btn>
          </v-row>
        </v-col>
        <v-col md="4" cols="12" >
          <div class="siderBox">
            <header>
              <strong>Latest Blogs</strong>
              <router-link to="/blogs"
                >View All
                <v-icon> mdi-chevron-double-right </v-icon></router-link
              >
            </header>
            <div v-if="!loading">
              <BlogListSide  v-for="(data, index) in blogs"
            :key="index + 'blog'" :item="data"/>
            </div>
            <div v-if="loading">
                <LoadingSkeleton image="1"/>
            </div>
          </div>
        </v-col>
   
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BlogBox from "@/components/BlogBox";
import BlogListSide from "@/components/BlogListSide";
import Loading from "@/components/Loading";
import LoadingSkeleton from "@/components/LoadingSkeleton";
export default {
  name: "Blog",
  components: { BlogBox, BlogListSide,Loading,LoadingSkeleton },
  data: () => ({
    blogs: [],
    limit: 10,
    page: 1,
    loading: true,
    next:undefined
  }),
  computed: {
    ...mapGetters(["allBlogs"]),
  },
  methods: {
    ...mapActions(["getFrontendBlogs"]),
  },
  async created() {
    await this.getFrontendBlogs({ limit: this.limit, page: this.page });
    this.blogs = this.allBlogs.results;
    this.next = this.allBlogs.pagination.next;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.navList {
  padding-left: 0;
  list-style: none;
  display: flex;
  font-size: 14px;
  li {
    .v-icon {
      font-size: 18px;
      margin-left: 5px;
    }
    // margin-right: 15px;
  }
}
.viewMore {
  .v-btn {
    text-transform: capitalize;
    min-height: 60px !important;
    width: 200px;
  }
}
.compareBox {
  cursor: pointer;
}
.primary {
  border: 1px solid blueviolet;
  min-height: 50px;
  width: 220px;
  background: transparent !important;
  box-shadow: none !important;
  color: blueviolet !important;
}
</style>