<template>
  <section>
    <v-container class="container-custom">
      <v-row>
        <div class="fullForm">
          <h2>Filter</h2>
          <v-form>
            <v-row>
              <v-col md="3">
                <div>
                  <v-text-field
                    v-model="blogTitle"
                    outlined
                    placeholder="Blog Name"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col md="3">
                <div>
                  <v-select
                    v-model="category"
                    outlined
                    placeholder="Category"
                    :items="categories"
                    item-text="name"
                    item-value="name"
                    @change="selectCategory(category)"
                  ></v-select>
                </div>
              </v-col>
              <v-col md="3">
                <div>
                  <v-select
                    v-model="subCategory"
                    outlined
                    placeholder="Sub Category"
                    :items="subCategories"
                    item-text="name"
                    item-value="name"
                  ></v-select>
                </div>
              </v-col>
              <v-col>
                <div>
                  <v-btn x-large v-if="!submitLoading" @click="filterAct"
                    >Filter</v-btn
                  >
                  <v-btn x-large loading v-if="submitLoading"></v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <v-col md="12">
          <div class="titleAction">
            <h1 class="systemTitle">Blogs</h1>
            <router-link to="/business/addblog"
              ><v-btn large>
                <v-icon class="mr-2"> mdi-plus </v-icon> Add Blog</v-btn
              ></router-link
            >
          </div>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr class="greenBack">
                  <th class="text-left">Image</th>
                  <th class="text-left">Name</th>
                  <th class="text-left">Category</th>
                  <th class="text-left">Subcategory</th>
                  <th class="text-left">Action</th>
                </tr>
              </thead>
              <tbody v-if="!loading">
                <tr
                  v-for="(data, index) in blogs"
                  :key="index + 'product'"
                  class="rowBox"
                >
                  <td width="120">
                    <img
                      v-if="data.images.length > 0"
                      :src="data.images[0].image"
                      alt=""
                      width="100"
                    />
                  </td>
                  <td>{{ data.blogTitle }}</td>
                  <td>{{ data.category }}</td>
                  <td class="text-capitalize">{{ data.subCategory }}</td>
                  <td width="10">
                    <div class="d-flex">
                      <router-link :to="`/business/addblog/${data._id}`"
                        ><v-icon> mdi-square-edit-outline </v-icon></router-link
                      >
                      <v-icon
                        class="ml-3"
                        v-if="!loadingDelete || id != data._id"
                        @click="removeBlog(data._id)"
                      >
                        mdi-delete
                      </v-icon>
                      <v-progress-circular
                        class="ml-3"
                        indeterminate
                        v-if="loadingDelete && id == data._id"
                        size="20"
                        width="2"
                      ></v-progress-circular>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-if="loading">
                <tr>
                  <td>
                    Loading
                    <v-progress-circular
                      indeterminate
                      size="20"
                      width="2"
                      class="ml-2"
                    ></v-progress-circular>
                  </td>
                </tr>
              </tbody>

              <tfoot v-if="!loading">
                <tr>
                  <td colspan="5">
                    <ul class="pagination">
                      <li v-if="pagination.previous">
                        <v-icon @click="prev(pagination.previous)">
                          mdi-chevron-left
                        </v-icon>
                      </li>
                      <li>
                        <span v-if="pagination.previous && !pagination.next"
                          >{{
                            pagination.previous + 1 > 1
                              ? pagination.previous + 1 * limit
                              : 1
                          }}
                          -</span
                        >
                        <span v-if="pagination.previous && pagination.next"
                          >{{
                            pagination.previous + 1 > 1
                              ? pagination.previous + 1 * limit
                              : 1
                          }}
                          -</span
                        >
                        <span v-if="pagination.next && !pagination.previous"
                          >{{
                            pagination.next - 1 > 1
                              ? pagination.next - 1 * limit
                              : 1
                          }}
                          -</span
                        >
                        {{ pagination.total }} Items
                      </li>
                      <li v-if="pagination.next">
                        <v-icon @click="next(pagination.next)">
                          mdi-chevron-right
                        </v-icon>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table></v-col
        >
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Blog",
  data: () => ({
    blogs: [],
    loading: true,
    loadingDelete: false,
    pagination: undefined,
    limit: 10,
    page: 1,
    id: undefined,
    filters: undefined,
    category: undefined,
    categories: [],
    subCategory: undefined,
    subCategories: [],
    submitLoading: false,
    blogTitle: undefined,
  }),
  computed: {
    ...mapGetters(["allBlogs", "allCategory"]),
  },
  methods: {
    ...mapActions(["getBlogs", "deleteBlog", "fetchCategory"]),
    async removeBlog(id) {
      this.loadingDelete = true;
      this.id = id;
      await this.deleteBlog(id);
      this.loadingDelete = false;
      this.models = this.allBlogs.results;
      this.pagination = this.allBlogs.pagination;
    },
    async next(data) {
      this.loading = true;
      await this.getBlogs({ limit: this.limit, page: data });
      this.models = this.allBlogs.results;
      this.pagination = this.allBlogs.pagination;
      this.loading = false;
    },
    async prev(data) {
      this.loading = true;
      await this.getBlogs({ limit: this.limit, page: data });
      this.models = this.allBlogs.results;
      this.pagination = this.allBlogs.pagination;
      this.loading = false;
    },
    selectCategory(id) {
      this.subCategories = [];
      if (id != undefined) {
        let filteredData = this.allCategory.results.filter((ele) => {
          return id == ele.name;
        });
        if (filteredData.length > 0) {
          this.subCategories = filteredData[0].subCategories;
        }
      }
    },
    async filterAct() {
      this.submitLoading = true;
      let filter = {};
      if (this.blogTitle != undefined && this.blogTitle != "") {
        filter.blogTitle = this.blogTitle;
      }
      if (this.category != undefined && this.category != "") {
        filter.category = this.category;
      }
      if (this.subCategory != undefined && this.subCategory != "") {
        filter.subCategory = this.subCategory;
      }
      await this.getBlogs({ limit: this.limit, page: this.page, ...filter });
      this.blogs = this.allBlogs.results;
      this.submitLoading = false;
    },
  },
  async created() {
    await this.getBlogs({ limit: this.limit, page: this.page });
    this.blogs = this.allBlogs.results;
    this.pagination = this.allBlogs.pagination;
    this.loading = false;
    await this.fetchCategory();
    this.categories = this.allCategory.results;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.pagination {
  list-style: none;
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .v-icon {
    font-size: 18px;
    height: 30px;
    width: 30px;
    display: flex;
  }
}
.titleAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  a {
    text-decoration: none;
  }
}
.rowBox {
  td {
    padding: 10px !important;
  }
}
.fullForm {
  margin-top: 20px;
  width: 100%;
  height: 100px;
  padding: 0 15px;
  h2 {
    margin-bottom: 10px;
  }
}
</style>